import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar'; // Import Sidebar
import { TwitterPicker } from 'react-color'; // Modern Color Picker
import { useDropzone } from 'react-dropzone'; // Drag & Drop for Image Upload
import '../styles/BannerCreatorPage.css'; // Add custom styles for the page
import { collection, getDocs } from "firebase/firestore";
import { db } from '../firebase';

const BannerCreatorPage = () => {
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [bannerName, setBannerName] = useState(''); // State for Banner Name
    const [buttonText, setButtonText] = useState('Click Me');
    const [banners, setBanners] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBanners = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "banners"));
                const fetchedBanners = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setBanners(fetchedBanners);
            } catch (error) {
                console.error("Error fetching banners: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchBanners();
    }, []);

    const uploadFile = async (file) => {
        const uploadUrl = 'https://api-ckgoat.greatshark.in/storage/upload/';
        const bearerToken = 'gCW0z7uTiZqbNwoYQsDvE2gAPxdHfXciazPmCzPneXpn444glZ'; // Replace with your actual token

        try {
            const formData = new FormData();
            formData.append('files', file);

            const response = await fetch(uploadUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${bearerToken}`,
                    'Content-Type': 'multipart/form-data',
                },
                body: formData,
            });

            if (response.status === 201) {
                const jsonResponse = await response.json();
                if (jsonResponse.file_urls) {
                    return jsonResponse.file_urls[0]; // Return the uploaded file URL
                } else {
                    console.error('Error:', jsonResponse.error);
                    return null;
                }
            } else {
                console.error('Error: Server responded with status code', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            return null;
        }
    };


    const [buttonStyle, setButtonStyle] = useState({
        color: '#ffffff',
        backgroundColor: '#007bff',
        position: 'center',
        margin: { vertical: 0, horizontal: 0 },
        type: 'link',
        value: '',
    });

    const handleImageUpload = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            try {
                const uploadedImageUrl = await uploadFile(file);
                if (uploadedImageUrl) {
                    setBackgroundImage(uploadedImageUrl); // Set the uploaded image URL
                    alert("Image uploaded successfully!");
                } else {
                    alert("Failed to upload image.");
                }
            } catch (error) {
                console.error("Error during image upload:", error);
                alert("An error occurred while uploading the image.");
            }
        }
    };



    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleImageUpload,
        accept: 'image/*',
    });

    const updateStyle = (key, value) => {
        setButtonStyle((prev) => ({ ...prev, [key]: value }));
    };

    const updateMargin = (key, value) => {
        setButtonStyle((prev) => ({
            ...prev,
            margin: { ...prev.margin, [key]: parseInt(value, 10) },
        }));
    };

    const positionStyles = {
        'top-left': {
            top: `${buttonStyle.margin.vertical}px`,
            left: `${buttonStyle.margin.horizontal}px`,
        },
        'top-right': {
            top: `${buttonStyle.margin.vertical}px`,
            right: `${buttonStyle.margin.horizontal}px`,
        },
        'bottom-left': {
            bottom: `${buttonStyle.margin.vertical}px`,
            left: `${buttonStyle.margin.horizontal}px`,
        },
        'bottom-right': {
            bottom: `${buttonStyle.margin.vertical}px`,
            right: `${buttonStyle.margin.horizontal}px`,
        },
        center: {
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const handleSave = () => {
        const bannerData = {
            name: bannerName,
            backgroundImage,
            buttonText,
            buttonStyle,
        };
        console.log('Banner Saved:', bannerData);
        alert('Banner Saved Successfully!');
    };

    return (
        <div className="dashboard-container">
            <Sidebar activePage="banner-creator" />

            <div className="banner-creator-content">
                <div className="banner-list">
                    <h2>Your Banners</h2>
                    {loading ? (
                        <p>Loading...</p>
                    ) : banners.length > 0 ? (
                        <ul>
                            {banners.map((banner) => (
                                <li key={banner.id} className="banner-item">
                                    <div
                                        className="banner-thumbnail"
                                        style={{
                                            backgroundImage: `url(${banner.backgroundImage})`,
                                        }}
                                    ></div>
                                    <div>
                                        <h3>{banner.name}</h3>
                                        <p>{banner.buttonText}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No banners found.</p>
                    )}
                </div>
                <div className="banner-editor">
                    {/* Banner Name Input */}
                    <div className="row-group">
                        <label>Banner Name:</label>
                        <input
                            type="text"
                            value={bannerName}
                            onChange={(e) => setBannerName(e.target.value)}
                            placeholder="Enter Banner Name"
                            className="input"
                        />
                    </div>

                    <h2>Banner Editor</h2>
                    <div {...getRootProps()} className="dropzone">
                        <input {...getInputProps()} />
                        <p>Drag & drop an image here, or click to upload</p>
                    </div>

                    <label>Button Text:</label>
                    <input
                        type="text"
                        value={buttonText}
                        onChange={(e) => setButtonText(e.target.value)}
                        className="input"
                    />

                    <div className="row-group">
                        <div className="color-picker">
                            <label>Button Text Color:</label>
                            <TwitterPicker
                                color={buttonStyle.color}
                                onChangeComplete={(color) => updateStyle('color', color.hex)}
                            />
                        </div>
                        <div className="color-picker">
                            <label>Button Background Color:</label>
                            <TwitterPicker
                                color={buttonStyle.backgroundColor}
                                onChangeComplete={(color) => updateStyle('backgroundColor', color.hex)}
                            />
                        </div>
                    </div>

                    <label>Position:</label>
                    <select
                        value={buttonStyle.position}
                        onChange={(e) => updateStyle('position', e.target.value)}
                        className="input"
                    >
                        <option value="center">Center</option>
                        <option value="top-left">Top Left</option>
                        <option value="top-right">Top Right</option>
                        <option value="bottom-left">Bottom Left</option>
                        <option value="bottom-right">Bottom Right</option>
                    </select>

                    <div className="row-group">
                        <div className="margin-input">
                            <label>Vertical Margin (px):</label>
                            <input
                                type="number"
                                value={buttonStyle.margin.vertical}
                                onChange={(e) => updateMargin('vertical', e.target.value)}
                                className="input"
                            />
                        </div>
                        <div className="margin-input">
                            <label>Horizontal Margin (px):</label>
                            <input
                                type="number"
                                value={buttonStyle.margin.horizontal}
                                onChange={(e) => updateMargin('horizontal', e.target.value)}
                                className="input"
                            />
                        </div>
                    </div>

                    <label>Button Type:</label>
                    <select
                        value={buttonStyle.type}
                        onChange={(e) => updateStyle('type', e.target.value)}
                        className="input"
                    >
                        <option value="link">Link</option>
                        <option value="call">Call</option>
                    </select>

                    {buttonStyle.type === 'call' && (
                        <div>
                            <label>Phone Number:</label>
                            <input
                                type="text"
                                placeholder="Enter phone number"
                                value={buttonStyle.value}
                                onChange={(e) => updateStyle('value', e.target.value)}
                                className="input"
                            />
                        </div>
                    )}

                    {buttonStyle.type === 'link' && (
                        <div>
                            <label>URL:</label>
                            <input
                                type="text"
                                placeholder="Enter URL"
                                value={buttonStyle.value}
                                onChange={(e) => updateStyle('value', e.target.value)}
                                className="input"
                            />
                        </div>
                    )}

                    {/* Save Button */}
                    <button onClick={handleSave} className="save-button">
                        Save Banner
                    </button>
                </div>

                <div className="banner-preview">
                    <div
                        style={{
                            ...styles.banner,
                            backgroundImage: `url(${backgroundImage})`,
                        }}
                    >
                        <a
                            href={
                                buttonStyle.type === 'call'
                                    ? `tel:${buttonStyle.value || ''}`
                                    : buttonStyle.type === 'link'
                                        ? buttonStyle.value || '#'
                                        : '#'
                            }
                            style={{
                                ...styles.button,
                                color: buttonStyle.color,
                                backgroundColor: buttonStyle.backgroundColor,
                                ...positionStyles[buttonStyle.position],
                            }}
                        >
                            {buttonText}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

const styles = {
    banner: {
        width: '380px',
        height: '200px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '8px',
        position: 'relative',
        border: '1px solid #ccc',
    },
    button: {
        position: 'absolute',
        textDecoration: 'none',
        padding: '10px 20px',
        borderRadius: '4px',
        fontSize: '14px',
        cursor: 'pointer',
    },
};

export default BannerCreatorPage;
